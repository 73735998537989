<template xmlns="http://www.w3.org/1999/html">
  <div class="m-4 text-lg help-text">
    <h1>Ieteikumi izglītības iestādēm gaisa kvalitātes nodrošināšanai un uzlabošanai</h1>

    <h3>Gaisa kvalitātes rādītāju ietekme uz veselību</h3>

    <p></p><span class="text-important">420 ppm</span> ir svaiga gaisa CO<sub>2</sub> līmenis, zemāku līmeni ar vēdināšanas palīdzību nav iespējams sasniegt.</p>
    <b>Gaisa kvalitātes rādījumi:</b>
    <ul class="list-info">
      <li><span class="text-important">420 ppm - 1000 ppm</span> – gaisa kvalitāte ir laba.</li>
      <li><span class="text-important">1000 ppm - 2000 ppm</span> – gaisa kvalitāte pasliktinās.<br>
        Nepieciešams pievērst uzmanību un sekot līdzi rādījumu izmaiņām, ja iespējams, vēdināt telpu, piem. izmantot telpas logu atvēršanu vēdināšanas
        režīmā.
      </li>
      <li><span class="text-important">2000 - 3000 ppm</span> -gaisa kvalitāte ir pasliktinājusies, var samazināties cilvēku kognitīvās spējas.<br>
        Ieteicams veikt pilnvērtīgu telpas vēdināšanu (min. 5 – 15 minūtes), turpinot nodarbību pēc atbilstoša CO<sub>2</sub> līmeņa sasniegšanas. Būtiski
        atcerēties, ka pie zemām āra gaisa temperatūrām telpu iespējams izvēdināt daudz ātrāk, kā līdzīgās iekštelpu/ārtelpu temperatūrās, kad
        spiediena starpība ir neliela.
      </li>
      <li><span class="text-important">virs 3000 ppm</span> - telpā ir būtiski palielināts CO<sub>2</sub> līmenis, kas var izraisīt pastiprinātu nogurumu,
        miegainību, darbaspēju samazināšanos, uztveres zudumu.<br>
        Ieteicams pēc iespējas ātrāk izvēdināt telpu, lai samazinātu CO<sub>2</sub> līmeni.<br>
        Ilgstoša uzturēšanās šādā telpā var radīt draudus veselībai.
      </li>
      <li><span class="text-important">virs 5000 ppm</span> - telpā ir bīstama CO<sub>2</sub> koncentrācija.<br>
        Uzturēšanās šādā telpā var radīt draudus veselībai. Nekavējoties atbrīvot telpu un izvēdināt to.
      </li>
    </ul>

    <h3>Ieteikumi gaisa kvalitātes nodrošināšanai telpās</h3>
    <ul class="list-info">
      <li>Izvēdināt telpu pirms pirmās mācību stundas/nodarbības telpā;</li>
      <li>Vēdināt telpu pēc katras mācību stundas (5-15 min.), lai samazinātu CO<sub>2</sub> koncentrāciju gaisā. Jāatceras, ka, atverot logu, CO<sub>2</sub> līmenis
        samazinās pakāpeniski un, ņemot vērā to, ka gaisa kvalitātes mērījumi tiek veikti ar 2 minūšu intervālu, kā arī to, ka mērītājam ir zināma
        inerce – lai gan telpa ir izvēdināta, gaisa kvalitātes mērījuma korektais rādījums uzrādīsies dažas minūtes vēlāk;
      </li>
      <li>Izvēdināt telpu pēc pēdējās mācību stundas/nodarbības telpā;</li>
      <li>Ja uzsākot nodarbību, CO<sub>2</sub> līmenis ir atbilstošs, bet nodarbības laikā strauji paaugstinās, tad nodarbības laikā jāieplāno īsa pauze, kuras
        laikā telpa tiek izvēdināta. Šādā gadījumā, iespējams, palīdzēs arī logu pasīva ventilācijas režīma uzturēšana (atvāzti logi minimālā
        ventilācijas režīmā). Ilgtermiņā šādos gadījumos izglītības iestādei ir jāpārdomā iespēja pārplānot nodarbības, samazinot personu skaitu, kas
        vienlaikus uzturas telpā;
      </li>
      <li>Ja veicot gaisa kvalitātes mērījumus tiek secināts, ka ar telpas vēdināšanu pārtraukumos starp mācību stundām/nodarbībām nav iespējams
        sasniegt CO<sub>2</sub> līmeni, kāds nepieciešams kvalitatīvam mācību procesam, ieteicams pagarināt starpbrīdi/pārtraukumu vismaz par 5
        minūtēm, lai iegūtu pietiekamu laiku telpas vēdināšanai;
      </li>
      <li>Ja telpā ir mehāniskā ventilācija, tad ventilācijas sistēmas darbības jāpielāgo, lai nodrošinātu labai gaisa kvalitātei atbilstošu rādītāju
        uzturēšanu telpā;
      </li>
      <li>Jāpārliecinās, ka izglītības iestādes ventilācijas sistēma ir darba kārtībā, tai tiek veikta nepieciešamā tehniskā apkope (ventilācijas
        kanāli un filtrējošās iekārtas tiek regulāri tīrīti/ atjaunoti) un tā tiek arī atbilstoši darbināta.
      </li>
    </ul>
    Efektīvākais veids kā ātri nodrošināt svaigā gaisa pieplūdumu un gaisa kvalitāti, ir veikt regulāru telpu vēdināšanu, atverot logus un durvis. Lai
    panāktu efektīvu CO<sub>2</sub> koncentrācijas kritumu mācību telpā, jāievēro šādi pareizas telpas vēdināšanas pamatnosacījumi:
    <ul class="list-info">
      <li>vēdināšanas laikā telpā neuzturas izglītojamie;</li>
      <li>vēdināšana tiek veikta ar pilnībā, nevis pusvirus atvērtu logu (-iem);</li>
      <li>vēdina, atverot visas konkrētās telpas logus un durvis, ja pastāv tāda iespēja;</li>
      <li>starpbrīdim atvēlētais laiks maksimāli tiek izmantots vēdināšanai.</li>
    </ul>

    <h3>Ieteikumi dibinātājam gaisa kvalitātes uzlabošanai/nodrošināšanai izglītības iestādēs</h3>

    <p>Izglītības iestādes dibinātājam ir jāseko gaisa kvalitātes stāvoklim izglītības iestādē, izmantojot portāla <span style="color: #1890ff">co2.mesh.lv</span>
      datus.</p>

    <p>Neapmierinošas gaisa kvalitātes gadījumā izglītības iestādes dibinātājam jāveic pasākumi gaisa kvalitātes uzlabošanai, t.sk. piespiedu
      ventilācijas sistēmu izbūve izglītības iestādēs, lai nodrošinātu gaisa kvalitātes prasību ievērošanu.</p>

    <p>Gan izglītības iestādēm, gan izglītības iestāžu dibinātājiem jāņem vērā, ka no 2024.gada, pārejot uz izglītības iestāžu institucionālo
      akreditāciju, gaisa kvalitātes prasību ievērošana ir paredzēta kā viens no novērtēšanas kritērijiem.</p>

    <p>Saskaņā ar likumu „Par valsts budžetu 2022. gadam” pašvaldībām pieejami Valsts kases aizdevumi investīciju projektu, tajā skaitā izglītības
      iestāžu investīciju projektu īstenošanai. Līdzekļi tiek piešķirti atbilstoši Ministru kabineta 2022. gada 22. februāra noteikumiem Nr. 143
      “Noteikumi par kritērijiem un kārtību, kādā 2022. gadā tiek izvērtēti un izsniegti valsts aizdevumi pašvaldībām Covid-19 izraisītās krīzes seku
      mazināšanai un novēršanai”, viens no atbalstāmajiem investīciju virzieniem ir izglītības iestāžu infrastruktūras modernizācija, tai skaitā, arī
      atbilstošu ventilācijas sistēmu izbūve kvalitatīvas mācību vides un izglītības iestādēm noteikto higiēnas prasību izpildei.</p>

    <p>Pašvaldība var īstenot izglītības iestāžu mācību vides uzlabošanas pasākumus, piesakot projektu Ministru kabineta 2022. gada 8. februāra
      noteikumu Nr. 112 “Kārtība, kādā piešķiramas un izlietojamas mērķdotācijas pašvaldībām ietvaros”.</p>

    <p>&nbsp;</p>
  </div>

</template>

<script>
export default {
  name: "Recomendations"
}
</script>

<style scoped>
.help-text {
  max-width: 950px;
}

h1 {
  font-size: 24px;
  font-weight: bold;
}

h3 {
  font-size: 20px;
  font-weight: bold;
  color: #1890ff;
}

.text-important {
  color: #689f38;
  font-weight: bold;
}
</style>
